<template>
  <div>
    <el-dialog :title="'选择' + title" :center="true" :width="dialogWidth" :modal-append-to-body="false"
      :visible="dialogTableVisible" @close="dialogClose">
      <div class="bef0 bbnone">
        <!-- 三方平台 -->
        <el-form v-if="selectType == 'model'" label-width="78px">
          <el-form-item label="平台类型">
            <el-select v-model="ruleForm.type" placeholder="请选择" @change="changeExploreType" style="width: 100%">
              <el-option :disabled="item.id == 0 ? false : true" v-for="item in typeList" :key="item.id" :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择分类">
            <el-select v-model="ruleForm.selectClass" placeholder="请选择" @change="changeClassfiy" style="width: 100%">
              <el-option v-for="item in classfiyList" :key="item.typeId" :label="item.labelName" :value="item.typeId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择内容">
            <el-select v-model="ruleForm.bId" placeholder="请选择" @change="changeApply" style="width: 100%">
              <el-option v-for="item in applyList" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 普通商品 -->
        <commonTable :tableData="memberData" :loading="loading" @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange" v-if="memberData[0] && selectType == 'isOrdinary'" :total="total">
          <template v-slot:table>
            <el-table-column align="center" width="100" label="选择商品">
              <template slot-scope="scope">
                <el-radio :label="scope.row.goodsId" v-model="goodsId"
                  @change="getTemplateRow(scope.$index, scope.row)"></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="goodsName" align="center" label="产品名称" />

            <el-table-column prop="goodsTypeName" align="center" label="分类" show-overflow-tooltip />
            <el-table-column prop="personalWx" align="center" label="价格" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ (scope.row.price / 100) + '~ ' + (scope.row.maxPrice / 100) }}元
              </template>
            </el-table-column>
            <el-table-column prop="stockNum" align="center" label="库存" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.stockNum }}
              </template>
            </el-table-column>
            <el-table-column prop="dealNum" align="center" label="总销量" show-overflow-tooltip />
            <el-table-column prop="createTime" width="200px" align="center" label="发布时间">
              <template slot-scope="scope">
                {{ scope.row.createTime | getDataTimeSec }}
              </template>
            </el-table-column>
          </template>
        </commonTable>
        <!-- 秒杀商品 -->
        <commonTable :tableData="memberData" :loading="loading" @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange" v-if="memberData[0] && selectType == 'isKill'" :total="total">
          <template v-slot:table>
            <el-table-column align="center" width="100" label="选择商品">
              <template slot-scope="scope">
                <el-radio :label="scope.row.goodsId" v-model="goodsId"
                  @change="getTemplateRow(scope.$index, scope.row)"></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="goodsName" align="center" label="产品名称" />

            <el-table-column prop="goodsTypeName" align="center" label="分类" show-overflow-tooltip />
            <el-table-column prop="personalWx" align="center" label="原价格" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ (scope.row.minPrice / 100) + '~' + (scope.row.maxPrice / 100) }}元
              </template>
            </el-table-column>
            <el-table-column prop="personalWx" align="center" label="秒杀价格" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ (scope.row.minKillPrice / 100) + '~' + (scope.row.maxKillPrice / 100) }}元
              </template>
            </el-table-column>
            <el-table-column prop="stockNum" align="center" label="库存" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.stockNum }}
              </template>
            </el-table-column>
            <el-table-column prop="totalBuyNum" align="center" label="总销量" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.totalBuyNum }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" width="210px" align="center" label="秒杀活动时间">
              <template slot-scope="scope">
                {{ scope.row.createTime | getDataTimeSec }} - {{ scope.row.endTime | getDataTimeSec }}
              </template>
            </el-table-column>
          </template>
        </commonTable>
        <!-- 拼团商品 -->
        <commonTable :tableData="memberData" :loading="loading" @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange" v-if="memberData[0] && selectType == 'isAssemble'" :total="total">
          <template v-slot:table>
            <el-table-column align="center" width="100" label="选择商品">
              <template slot-scope="scope">
                <el-radio :label="scope.row.goodsId" v-model="goodsId"
                  @change="getTemplateRow(scope.$index, scope.row)"></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="goodsName" align="center" label="产品名称" />

            <el-table-column prop="goodsTypeName" align="center" label="分类" show-overflow-tooltip />
            <el-table-column prop="personalWx" align="center" label="原价格" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ (scope.row.minPrice / 100) + '~' + (scope.row.maxPrice / 100) }}元
              </template>
            </el-table-column>
            <el-table-column prop="personalWx" align="center" label="拼团价格" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ (scope.row.minAssemblePrice / 100) + '~' + (scope.row.maxAssemblePrice / 100) }}元
              </template>
            </el-table-column>
            <el-table-column prop="stockNum" align="center" label="库存" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.stockNum }}
              </template>
            </el-table-column>
            <el-table-column prop="dealNum" align="center" label="总销量" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.dealNum }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" width="210px" align="center" label="拼团活动时间">
              <template slot-scope="scope">
                {{ scope.row.createTime | getDataTimeSec }} - {{ scope.row.endTime | getDataTimeSec }}
              </template>
            </el-table-column>
          </template>
        </commonTable>
        <!-- 分销商品 -->
        <commonTable :tableData="memberData" :loading="loading" @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange" v-if="memberData[0] && selectType == 'isDistribution'"
          :total="total">
          <template v-slot:table>
            <el-table-column align="center" width="100" label="选择商品">
              <template slot-scope="scope">
                <el-radio :label="scope.row.goodsId" v-model="goodsId"
                  @change="getTemplateRow(scope.$index, scope.row)"></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="goodsName" align="center" label="产品名称" />

            <el-table-column prop="goodsTypeName" align="center" label="分类" show-overflow-tooltip />
            <el-table-column prop="personalWx" align="center" label="价格" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ (scope.row.price / 100) + '~ ' + (scope.row.maxPrice / 100) }}元
              </template>
            </el-table-column>
            <el-table-column prop="stockNum" align="center" label="库存" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.stockNum }}
              </template>
            </el-table-column>
            <el-table-column prop="dealNum" align="center" label="总销量" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.dealNum }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" width="200px" align="center" label="发布时间">
              <template slot-scope="scope">
                {{ scope.row.createTime | getDataTimeSec }}
              </template>
            </el-table-column>
          </template>
        </commonTable>
        <!-- 预约商品 -->
        <commonTable :tableData="memberData" :loading="loading" @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange" v-if="memberData[0] && selectType == 'appointment'" :total="total">
          <template v-slot:table>
            <el-table-column align="center" width="100" label="选择商品">
              <template slot-scope="scope">
                <el-radio :label="scope.row.productsId" v-model="productsId"
                  @change="appointmentRow(scope.$index, scope.row)"></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="productsName" align="center" label="产品名称" />

            <el-table-column prop="productsTypeName" align="center" label="分类" show-overflow-tooltip />
            <el-table-column prop="personalWx" align="center" label="价格" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.price }}元
              </template>
            </el-table-column>
            <el-table-column prop="serviceType" align="center" label="服务方式" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.serviceType == 1 ? '到店' : scope.row.serviceType == 3 ? '到店 - 上门' : '上门' }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" width="200px" align="center" label="发布时间">
              <template slot-scope="scope">
                {{ scope.row.createTime | getDataTimeSec }}
              </template>
            </el-table-column>
          </template>
        </commonTable>
        <!-- 商品分类 -->
        <commonTable :tableData="memberData" :loading="loading" @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange" v-if="memberData[0] && selectType == 'classify'" :total="total">
          <template v-slot:table>
            <el-table-column align="center" width="100" label="选择分类">
              <template slot-scope="scope">
                <el-radio :label="scope.row.goodstypeId" v-model="goodstypeId"
                  @change="goodsTypeRow(scope.$index, scope.row)"></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="分类名称" />

            <el-table-column prop="goodsNum" align="center" label="商品数量" show-overflow-tooltip />
            <el-table-column prop="dealNum" align="center" label="库存" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.dealNum }}
              </template>
            </el-table-column>

            <el-table-column prop="createTime" width="200px" align="center" label="发布时间">
              <template slot-scope="scope">
                {{ scope.row.createTime | getDataTimeSec }}
              </template>
            </el-table-column>
          </template>
        </commonTable>

        <div class="flex-c-c w100P flex-column pt20 pb10" v-else-if="!memberData[0]">
          <p class="mb20">暂无{{ selectType == 'classify' ? '分类' : '商品' }}，需要设置{{ selectType == 'classify' ? '分类' : '商品' }}</p>
          <el-button type="primary" plain @click="setGoods">
            去设置
          </el-button>
        </div>
      </div>
      <div class="mt40 flex-c-c">
        <el-button type="primary" plain @click="cancel">
          取消
        </el-button>
        
        <el-button v-if="selectType == 'model'" type="primary" @click="submitModel">
          确认
        </el-button>
        <el-button v-else type="primary" @click="onConfirmShop">
          确认
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getDataTimeSec } from "@/utils";
import commonTable from "@/components/common/commonTable";
import { blendQueryPage,typeQueryPage } from "@/api/drawingBed";
export default {
  props: {
    memberData: {
      type: Array,
      default: () => []
    },
    dialogTableVisible: {
      type: Boolean,
      default: false
    },
    total: { //总条数
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: { //弹出框样式
      type: String,
      default: ''
    },
    dialogWidth: {
      default: '30%'
    },
    selectType: {
      type: String,
      default: ''
    },
    selectGoods: {
      type: Array
    },
    currentSelect: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      chooseCardId: null,
      goodsId: '',//选中的商品id
      goods: "", // 选中的商品
      goodstypeId: '',//选中分类id
      goodstype: '', //选中分类
      productsId: '', //预约商品id
      products: '', //预约商品
      classfiyList:[],
      typeList:[{
        title:'图壳应用',
        id:0
      },{
        title:'图壳模型',
        id:1
      },{
        title:'近迁全景',
        id:2
      }],
      applyList:[],
      ruleForm:{}
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val, 'yy-mm-dd');
    }
  },
  watch: {
    memberData(val) {
    },
    selectType(val) {
    }
  },
  components: {
    commonTable
  },
  methods: {
    changeExploreType(e){
      if(e==0){
        this.getClassfiyList();
      }
    },
    changeClassfiy(e){
      console.log(e,'e-------------');
      this.getapplyList(e)
    },
    getapplyList(id) {
      let data = {
        pageNum: 1,
        pageSize: 100,
        typeId:id
      };
      blendQueryPage(data).then((res) => {
        this.applyList = res.data.records;
      });
    },
     // 选择应用
     changeApply(e) {
      console.log(e, "88888");
      this.applyList.forEach((el) => {
        if (e == el.id) {
          this.ruleForm.url = el.realUrl;
          this.ruleForm.title = el.title;
          this.ruleForm.icon = el.icon;
          this.fileList = [{ name: "img", url: el.icon }];
        }
      });
    },
    // 获取分类列表
    getClassfiyList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
      };
      typeQueryPage(data).then((res) => {
        this.classfiyList = res.data.records;
      });
    },
    // 选择分类
    goodsTypeRow(index, row) {
      this.goodstypeId = row.goodstypeId
      this.goodstype = row
    },
    // 选择商品
    getTemplateRow(index, row) {
      this.goodsId = row.goodsId;
      this.goods = row;
    },
    // 选择预约商品
    appointmentRow(index, row) {
      this.productsId = row.productsId
      this.products = row
    },
    //弹框页面切换
    dialogClose(val) {
      this.$emit('dialogClose');
    },
    checkTap(id) {
      this.chooseCardId = null
      this.chooseCardId = id
    },
    onConfirmMember() {
      // this.$emit('turnOverMember',this.chooseCardId);
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit('dialogCurrentChange', val);
    },
    //设置商品
    setGoods() {
      this.selectType
      switch (this.selectType) {
        case 'classify':
          this.$router.push('/management?name=second')
          break;
        case 'isOrdinary':
          this.$router.push('/redactOrAdd')
          break;
        case 'isKill':
          this.$router.push('/addSpike')
          break;
        case 'isAssemble':
          this.$router.push('/addAssemble')
          break;
        case 'isDistribution':
          this.$router.push('/redactOrAdd')
          break;
        case 'appointment':
          this.$router.push('/launchProducts')
          break;
        default:
          break;
      }
      this.$emit('dialogClose')
    },
    submitModel(){
      if(!this.ruleForm.bId){
        this.$message.warning('请选择模型！');
        return;
      }
      this.$emit('onConfirmShop', this.ruleForm, 'model' );
      this.dialogClose();
    },
    //确认商品
    onConfirmShop() {
      let than = this
      let goodsId = ''


      if (than.selectGoods && than.selectGoods[0].goods) {
        if (than.selectType == 'appointment') {
          try {
            than.selectGoods.forEach(item => {

              if (item.goods) {
                if (item.goods.productsId == than.productsId) {
                  throw new error
                }
              }

            })
          } catch {
            return than.$message.error('同模块下不能选择相同商品，请重新选择')
          }

        } else {
          try {
            than.selectGoods.forEach(item => {
              if (item.goods) {
                if (item.goods.goodsId == than.goodsId) {
                  throw new error
                }
              }

            })
          } catch {
            return than.$message.error('同模块下不能选择相同商品，请重新选择')
          }

        }


      }
      if (this.goodstype || this.products || this.goods) {

        if (this.selectType == 'classify') {
          let type = 'classify'
          this.$emit('onConfirmShop', this.goodstype, type, this.currentSelect)
        } else if (this.selectType == 'appointment') {
          let type = 'appointment'
          this.$emit('onConfirmShop', this.products, type, this.currentSelect)
        } else {
          this.$emit('onConfirmShop', this.goods, 'goods', this.currentSelect)
        }
        this.dialogClose()
      } else {
        this.$message.error('请选择对应的商品或分类')
      }

    },
    //取消
    cancel() {
      // this.goodsId = ''
      // this.goodstypeId = ''
      // this.productsId = ''
      this.dialogClose()
    },
    log(data) {
    }
  },
};
</script>

<style lang="scss" scoped></style>